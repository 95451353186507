import api from 'utils/api';

export const fetchTimelog = (id) =>
  api.callGet(`admin/task-timelog/campaign?campaign_id=${id}`);

export const fetchReferenceLink = (id) =>
  api.callGet(`admin/link?rel_id=${id}&rel_type=2`);

export const fetchCampaignByConcept = (conceptId, partnerId, page, search) =>
  api.callGet(
    `admin/concepts/get-campaigns?concept_id=${conceptId}&partner_id=${partnerId}&page=${page}`,
    { search: search }
  );

export const requestCampaignOverview = (campaignId) =>
  api.callGet(`admin/campaigns/overview/${campaignId}`);

// campaign task
export const requestCampaignTasks = (campaignId) =>
  api.callGet(`admin/campaigns/task/${campaignId}`);

export const fetchCampaign = (conceptId, partnerId) =>
  api.callGet(
    `admin/concepts/get-campaign?concept_id=${conceptId}&partner_id=${partnerId}`
  );

export const requestMilestoneTemplates = (campaignId) =>
  api.callGet(`admin/milestone-campaign/${campaignId}/campaign`);

export const requestMilestoneStart = (params) =>
  api.callPost(`admin/milestone/start-campaign`, params);

export const requestMilestoneUpdate = (params) =>
  api.callPost(`admin/milestone-campaign/update`, params);

export const requestMilestoneOriginalTimeline = (campaignId, channelId) =>
  api.callPost(
    `admin/milestone-campaign/update-original?campaign_id=${campaignId}&channel_id=${channelId}`
  );
