// Redux
import { createSlice } from '@reduxjs/toolkit';

import {
  requestCampaignOverview,
  requestCampaignTasks,
  requestMilestoneStart,
  requestMilestoneTemplates,
  requestMilestoneUpdate,
} from 'services/api/campaign';

const initialState = {
  overview: {},
  tasks: [],
  fetchCampaignOverview: false,
  fetchCampaignTasks: false,
  errorCampaignOverview: null,
  errorCampaignTasks: [],
  milestone: { templates: [], logs: [] },
  fetchMilestoneTemplates: false,
  errorMilestoneTemplates: null,
  fetchMilestoneLogs: false,
  errorMilestoneLogs: null,
};

const campaign = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    // Overview
    initCampaignOverview: (state) => {
      state.fetchCampaignOverview = true;
      state.errorCampaignOverview = null;
    },
    successCampaignOverview: (state, { payload }) => {
      state.overview = payload;
      state.fetchCampaignOverview = false;
      state.errorCampaignOverview = null;
    },
    errorCampaignOverview: (state, { payload }) => {
      state.fetchCampaignOverview = false;
      state.errorCampaignOverview = { message: payload };
    },
    // Campaign Tasks
    initCampaignTasks: (state) => {
      state.fetchCampaignTasks = true;
      state.errorCampaignTasks = null;
    },
    successCampaignTasks: (state, { payload }) => {
      state.tasks = payload;
      state.fetchCampaignTasks = false;
      state.errorCampaignTasks = null;
    },
    errorCampaignTasks: (state, { payload }) => {
      state.fetchCampaignTasks = false;
      state.errorCampaignTasks = { message: payload };
    },
    // Milestones
    initMilestoneTemplates: (state) => {
      state.fetchMilestoneTemplates = true;
      state.errorMilestoneTemplates = null;
    },
    successMilestoneTemplates: (state, { payload }) => {
      state.milestone.templates = payload;
      state.fetchMilestoneTemplates = false;
      state.errorMilestoneTemplates = null;
    },
    errorMilestoneTemplates: (state, { payload }) => {
      state.milestone.templates = payload;
      state.fetchMilestoneTemplates = false;
      state.errorMilestoneTemplates = null;
    },
    errorStartMilestoneTemplates: (state) => {
      state.fetchMilestoneTemplates = false;
      state.errorMilestoneTemplates = null;
    },
  },
});

export const {
  initCampaignOverview,
  successCampaignOverview,
  errorCampaignOverview,
  initCampaignTasks,
  successCampaignTasks,
  errorCampaignTasks,
  initMilestoneTemplates,
  successMilestoneTemplates,
  errorMilestoneTemplates,
  errorStartMilestoneTemplates,
} = campaign.actions;

export const getCampaignOverview = (campaignId) => async (dispatch) => {
  dispatch(initCampaignOverview());

  const { success, data, message } = await requestCampaignOverview(campaignId);

  success
    ? dispatch(successCampaignOverview(data))
    : dispatch(errorCampaignOverview(message));
};

export const getCampaignTask = (campaignId) => async (dispatch) => {
  dispatch(initCampaignTasks());

  const { success, data, message } = await requestCampaignTasks(campaignId);

  success
    ? dispatch(successCampaignTasks(data))
    : dispatch(errorCampaignTasks(message));
};

export const startMilestone = (params) => async (dispatch) => {
  dispatch(initMilestoneTemplates());
  const { success, error } = await requestMilestoneStart(params);

  return success || error;
};

export const updateMilestone = (params) => async () =>
  await requestMilestoneUpdate(params);

export const getMilestoneTemplates =
  (campaignId, shouldDisplayLoader = true) =>
  async (dispatch) => {
    shouldDisplayLoader && dispatch(initMilestoneTemplates());

    const { success, message, data } = await requestMilestoneTemplates(
      campaignId
    );

    success
      ? dispatch(successMilestoneTemplates(data))
      : dispatch(errorMilestoneTemplates(message));
  };

export const updateMilestoneOriginalTimeline =
  (campaignId, channelId) => async () => {
    const response = await requestMilestoneTemplates(campaignId, channelId);
    return response;
  };

export default campaign.reducer;
